<template>
    <div class="content">
        <!-- 一级菜单下面所拥有的二级菜单 -->
        <el-aside class="right_aside">
            <SideMenu :itemList="itemList"></SideMenu>
        </el-aside>
        <!-- 以及二级菜单所对应的页面 -->
        <div class="left_mian">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import SideMenu from '@/components/sidemenu/SideMenu';
export default {
    components: {
        SideMenu
    },
    data() {
        return {
            userType:localStorage.getItem('userType'),
            itemList: [
               
            ]
        };
    },
    created(){
 this.changeAside()
    },
    methods:{
           changeAside(){
              let  adminNavList =  [ {
                    path: '/CollaborativeManagement',
                    title: '协同设计',
                    icon: 'set-up',
                    children: [
                        { path: 'entrustedDesign', title: '委托设计' },
                        { path: 'dataTransmission', title: '文档资料传输' }
                    ]
                },
                {
                    path: 'CollaborativeManufacturing',
                    title: '协同制造',
                    children: [
                        { path: 'productionScheduleManagement', title: '生产进度管理' },
                        { path: 'manufacturingRelease', title: '制造需求发布' },
                        { path: 'demandPush', title: '制造需求推送' }
                    ]
                },
                {
                    path: 'productManagement',
                    title: '协同服务',
                    children: [
                        { path: 'video', title: '视频会议' },
                        { path: 'operationMaintenanceScheme', title: '运维方案' },
                        { path: 'manufacturingForum', title: '制造业论坛' }
                    ]
                },
                {
                    path: 'supplyCoordination',
                    title: '供应链协同',
                    children: [
                        { path: 'procurementTask', title: '采购任务' },
                        { path: 'orderManagement', title: '收发货管理' },
                        { path: 'evaluationManagement', title: '合同评价' }
                    ]
                }],
                gysNavLsit = [{
                    path: '/CollaborativeManagement',
                    title: '协同设计',
                    icon: 'set-up',
                    children: [
                        { path: 'entrustedDesign', title: '委托设计' },
                        { path: 'dataTransmission', title: '文档资料传输' }
                    ]
                },
                {
                    path: 'CollaborativeManufacturing',
                    title: '协同制造',
                    children: [
                        { path: 'productionScheduleManagement', title: '生产进度管理' },
                        { path: 'manufacturingRelease', title: '制造需求发布' },
                        { path: 'demandPush', title: '制造需求推送' }
                    ]
                },
                {
                    path: 'productManagement',
                    title: '协同服务',
                    children: [
                        { path: 'video', title: '视频会议' },
                        { path: 'operationMaintenanceScheme', title: '运维方案' },
                        { path: 'manufacturingForum', title: '制造业论坛' }
                    ]
                },
                {
                    path: 'supplyCoordination',
                    title: '供应链协同',
                    children: [
                        { path: 'procurementTask', title: '采购任务' },
                        { path: 'orderManagement', title: '收发货管理' },
                      
                    ]
                }],
                hzNavList = [
                    {
                    path: '/CollaborativeManagement',
                    title: '协同设计',
                    icon: 'set-up',
                    children: [
                        { path: 'entrustedDesign', title: '委托设计' },
                        { path: 'dataTransmission', title: '文档资料传输' }
                    ]
                },
                {
                    path: 'CollaborativeManufacturing',
                    title: '协同制造',
                    children: [
                        { path: 'productionScheduleManagement', title: '生产进度管理' },
                        { path: 'manufacturingRelease', title: '制造需求发布' },
                        { path: 'demandPush', title: '制造需求推送' }
                    ]
                },
                {
                    path: 'productManagement',
                    title: '协同服务',
                    children: [
                        { path: 'video', title: '视频会议'  , },
                        { path: 'operationMaintenanceScheme', title: '运维方案' },
                        { path: 'manufacturingForum', title: '制造业论坛' }
                    ]
                },
                {
                    path: 'supplyCoordination',
                    title: '供应链协同',
                    children: [
                        { path: 'procurementTask', title: '采购任务' },
                        { path: 'orderManagement', title: '收发货管理' },
                        { path: 'evaluationManagement', title: '合同评价' }
                    ]
                }
                ]

                if(this.userType =='超级管理员'){
                    this.itemList  = adminNavList

                }else if(this.userType =='海装风电'){
                    this.itemList  = hzNavList
                }else if(this.userType =='供应商'){

                    this.itemList  =gysNavLsit
                }
           }

    }
};
</script>
<style scoped lang = 'scss'>
.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.right_aside {
    width: 300px;
    height: 100%;
}
.left_mian {
    overflow: hidden;
    width: 100%;
    height: 100%;
}
::v-deep .right_aside .sidebar .el-submenu__icon-arrow .el-icon-arrow-down {
    display: none;
    content: '';
}
</style>